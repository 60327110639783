import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Container from 'components/ui/Container';
import TitleSection from 'components/ui/TitleSection';
import Button from 'components/ui/Button';
import { ImageSharpFluid } from 'helpers/definitions';
import Carousel from 'components/ui/Carousel';

import * as Styled from './styles';

interface FormField {
  name: string;
  placeholder: string;
  options: string[];
}

interface Product {
  title: string;
  description: string;
  price: number;
  formFields: FormField[];
}

interface Props {
  data: {
    product: Product;
    images: {
      edges: {
        node: {
          childImageSharp: {
            fluid: ImageSharpFluid;
          }
          base: string;
        }
      }[]
    }
  };
  pageContext: {
    slug: string;
  };
}

interface FormFieldProps {
  label: React.ReactNode;
  children: React.ReactNode;
  htmlFor: string;
}

const FormFieldWrapper: React.FC<FormFieldProps> = ({ label, children, htmlFor }) => (
  <p>
    <label htmlFor={htmlFor}>
      {label} {children}
    </label>
  </p>
)

const ProductPage: React.FC<Props> = ({ data, pageContext }) => {
  const product = data.product;

  const extraFields = product.formFields.map((field, index, array) => {
    let choices = [""];
    choices.push(...field.options);
    const options = choices.map((option, index_1, array_1) => <option value={option}>{option}</option>);
    return (
      <FormFieldWrapper label={ field.name } htmlFor={ field.name }>
        <Styled.Select name={field.name} required={true} >
          {options}
        </Styled.Select>
      </FormFieldWrapper>
    )
  })

  const images = data.images.edges.map((edge) => (
    <Styled.Image>
      <Img fluid={edge.node.childImageSharp.fluid} alt={edge.node.base.split(".")[0] }/>
    </Styled.Image>
  ))

  const title = `${product.title} - \$${product.price.toFixed(2)}`

  return (
    <Layout>
      <SEO title={product.title} description={product.description} />
      <Container section>
        <TitleSection title="Product" subtitle={title} />

        <Styled.Images>
          <Carousel>
            {images}
          </Carousel>
        </Styled.Images>

        <Styled.Description>
          {product.description}
        </Styled.Description>

        <Styled.Description>
          Please fill out the form below to place an order. I will contact you via email with payment instructions.
        </Styled.Description>
        <Styled.OrderForm>
          <form name={pageContext.slug} 
                method="POST" 
                action="/products/order-received"
                data-netlify="true" 
                netlify-honeypot="bot-field">
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value={pageContext.slug} />
            <fieldset name="contact">
              <legend>Contact:</legend>
              <FormFieldWrapper label="Name" htmlFor="name">
                <Styled.Input type="text" required={true} id="name" name="Name" />
              </FormFieldWrapper>
              <FormFieldWrapper label="Email" htmlFor="email" >
                <Styled.Input type="email" required={true} id="email" name="Email" />
              </FormFieldWrapper>
            </fieldset>
            <fieldset name="options">
              <legend>Options:</legend>
              {extraFields}
            </fieldset>
            <fieldset name="shipping">
              <legend>Shipping:</legend>
              <FormFieldWrapper label="Name" htmlFor="shippingName" >
                <Styled.Input type="text" required={true} id="shippingName" name="Shipping name" />
              </FormFieldWrapper>
              <FormFieldWrapper label="Address Line 1" htmlFor="address1" >
                <Styled.Input type="text" required={true} id="address1" name="Address 1" />
              </FormFieldWrapper>
              <FormFieldWrapper label="Address Line 2" htmlFor="address2" >
                <Styled.Input type="text" id="address2" name="Address 2" />
              </FormFieldWrapper>
              <FormFieldWrapper label="City" htmlFor="city" >
                <Styled.Input type="text" required={true} id="city" name="City" />
              </FormFieldWrapper>
              <FormFieldWrapper label="State" htmlFor="state" >
                <Styled.Input type="text" required={true} id="state" name="State" />
              </FormFieldWrapper>
              <FormFieldWrapper label="Zip Code" htmlFor="zip" >
                <Styled.Input type="text" required={true} id="zip"name="Zip" />
              </FormFieldWrapper>
            </fieldset>
            <Button primary block type="submit">Place order</Button>
          </form>

        </Styled.OrderForm>
      </Container>
    </Layout>
  );
};

export default ProductPage;

export const query = graphql`
  query ProductBySlug($slug: String!) {
    product( slug: { eq: $slug }, type: { eq: "product" } ) {
      description
      price
      title
      formFields {
        name
        options
        placeholder
      }
    }
    images: allFile(filter: {relativePath: {glob: "products/braille-swing-cell/*.jpg"}}) {
      edges {
        node {
          base
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
