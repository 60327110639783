import styled from 'styled-components';
import tw from 'tailwind.macro';

export const Title = styled.h3`
  ${tw`font-semibold mb-4`};
`;

export const Image = styled.figure`
  ${tw`rounded-lg overflow-hidden p-px`};
`;

export const Links = styled.div`
  ${tw`w-full flex justify-between mt-10`};
`;

export const Description = styled.p`
  ${tw`w-full flex justify-between mt-2`};
`;

export const OrderForm = styled.section`
  ${tw`bg-white mt-auto mx-auto w-full sm:w-2/3 md:w-1/2`};

  form {
    ${tw`flex flex-col items-center justify-center mx-auto mt-4`};
  }

  p {
    ${tw`w-full mt-0`}
  }

  fieldset {
    ${tw`w-full border-gray-300 border p-2 m-2 rounded-md`}
  }

  legend {
    ${tw`text-xs text-indigo-600`}
  }

  label {
    ${tw`text-sm`}
  }
`;

export const Input = styled.input`
  ${tw`bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-md py-2 px-4 block w-full appearance-none leading-normal mb-3`};
`;

export const Select = styled.select`
  ${tw`bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-md py-2 px-4 w-full appearance-none leading-normal mb-3`};
`;

export const Images = styled.div`
  ${tw`max-w-screen-sm mx-auto w-full px-0 sm:px-16 my-6`};
`;
